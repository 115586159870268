import _get from 'lodash/get'
import _omit from 'lodash/omit'
import _xor from 'lodash/xor'
import _isUndefined from 'lodash/isUndefined'

import { Account } from 'AppCore/account/models'
import { Folder } from 'AppCore/folder/models'

import * as folders from 'AppCore/folder/api'
import { Member } from 'AppCore/member/models'

export interface FolderFormModel {
  name: string
  description: string
  member_ids: string[]
}

export function foldersData($q, EventsData, filteringContext, foldersCache, TutorialsFlags) {
  'ngInject'
  let service = {
    getFolder,
    getFolderMembers,
    getCount,
    create,
    update,
    move,
    remove
  }

  return service

  function getFolder(account: Account, folderId: string, options) {
    const defer = $q.defer()

    // Retrieve and parse 'folderSort' from local storage
    const folderSort = localStorage.getItem('folderSort')
    let sortName = ''

    if (folderSort) {
      try {
        const parsedSort = JSON.parse(folderSort)
        // Ensure parsedSort is an array and retrieve the 'name' from the first index
        if (parsedSort[0] && parsedSort[0].key === 'name') {
          sortName = parsedSort[0].direction === 'ASC' ? 'name' : '-name'
        }
      } catch (error) {
        console.error('Failed to parse folderSort from local storage:', error)
      }
    }

    // Construct coreParams, including 'sort' if sortName is available
    let coreParams = {
      include: _get(options, 'include', ''),
      ...(sortName && { sort: sortName })
    }

    let params = filteringContext.mergeParams(filteringContext.filters(), coreParams)
    let useCache = _get(options, 'cache') && !_get(options, 'filter')
    let cache

    if (useCache) {
      // Check the folders cache
      cache = foldersCache.get(folderId, _get(options, 'include'))
      if (cache) {
        return $q.when(cache)
      }
    }

    folders
      .fetchFolder({
        accountId: account.id,
        folderId: folderId,
        query: _get(options, 'filter') ? params : coreParams
      })
      .then((folder: Folder) => {
        if (useCache) {
          foldersCache.set(folderId, _get(options, 'include'), folder)
        }
        defer.resolve(folder)
      })
      .catch((rejection) => {
        defer.reject(rejection.response ? rejection.response.data : {})
      })

    return defer.promise
  }

  function getFolderMembers(account: Account, folderId: string, includes?: any) {
    const defer = $q.defer()

    // Retrieve and parse 'folderSort' from local storage
    const sortName = getSortNameFromLocalStorage()

    let query = {
      ...(includes && { include: includes }),
      ...(sortName && { sort: sortName })
    }

    folders
      .fetchFolderMembers({
        accountId: account.id,
        folderId: folderId,
        query
      })
      .then((members: Member[]) => {
        defer.resolve(members)
      })
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  function getCount(account: Account, folderId: string, filter, extraParams) {
    const defer = $q.defer()

    // Retrieve and parse 'folderSort' from local storage
    const sortName = getSortNameFromLocalStorage()

    let coreParams = {
      include: 'count',
      ...(sortName && { sort: sortName })
    }
    let params = filteringContext.mergeParams(extraParams, coreParams)

    folders
      .fetchFolder({
        accountId: account.id,
        folderId: folderId,
        query: filter ? params : coreParams
      })
      .then((folder: Folder) => {
        defer.resolve(folder.count)
      })
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  // Helper function to get 'folderSort' from local storage
  function getSortNameFromLocalStorage() {
    const folderSort = localStorage.getItem('folderSort')
    let sortName = ''

    if (folderSort) {
      try {
        const parsedSort = JSON.parse(folderSort)
        if (parsedSort[0] && parsedSort[0].key === 'name') {
          sortName = parsedSort[0].direction === 'ASC' ? 'name' : '-name'
        }
      } catch (error) {
        console.error('Failed to parse folderSort from local storage:', error)
      }
    }

    return sortName
  }

  function create(account: Account, model: FolderFormModel) {
    const defer = $q.defer()

    folders
      .createFolder({
        accountId: account.id,
        payload: _omit(model, 'member_ids')
      })
      .then((folder: Folder) => {
        TutorialsFlags.setUserFlag('created_folder')

        EventsData.pushEvent('folder_was_created', {
          folderId: folder.id,
          parentId: folder.parentId
        })

        return putMembers(account, folder, model.member_ids).then(function () {
          defer.resolve(folder)
        })
      })
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  /**
   * Update the properties of a folder.
   *
   * @param {Object} model - restmod object for folder with updated changes.
   * @param {Object} originalValues - original folder values before changes.
   */
  function update(
    account: Account,
    folder: Folder,
    model: FolderFormModel,
    originalValues: { name: string; description: string; memberIds: string[] }
  ) {
    const defer = $q.defer()

    if (originalValues.name === model.name) {
      _omit(model, 'name')
    }

    if (originalValues.description === model.description) {
      _omit(model, 'description')
    }

    folders
      .updateFolder({
        accountId: account.id,
        folderId: folder.id,
        payload: _omit(model, 'member_ids')
      })
      .then((folder: Folder) => {
        EventsData.pushEvent('folder_was_updated', {
          folderId: folder.id,
          parentId: folder.parentId
        })

        if (_xor(model.member_ids, originalValues.memberIds).length) {
          putMembers(account, folder, model.member_ids)
        }

        defer.resolve(folder)
      })
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  function move(account: Account, moveFolder, destinationFolder = account.folder, position?, firstFolderPosition?) {
    const defer = $q.defer()
    folders
      .updateFolder({
        accountId: account.id,
        folderId: moveFolder.id,
        payload: {
          parentId: destinationFolder.id,
          // some folders in the db start at zero and some at 1 so need to check this before setting the position
          ...(!_isUndefined(position) && {
            position: firstFolderPosition === 0 ? position + 1 : position + 2
          })
        }
      })
      .then((folder: Folder) => {
        EventsData.pushEvent('folder_was_updated', {
          folderId: folder.id,
          parentId: folder.parentId
        })
        if (moveFolder.parent_id != folder.parentId) {
          //if the folder is moved out of a folder, fire event for the original parent too
          EventsData.pushEvent('folder_was_updated', {
            folderId: folder.id,
            parentId: moveFolder.parent_id
          })
        }

        defer.resolve(folder)
      })
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  function remove(account: Account, folder: Folder) {
    const defer = $q.defer()

    folders
      .deleteFolder({
        accountId: account.id,
        folderId: folder.id
      })
      .then(() => {
        EventsData.pushEvent('folder_was_deleted', {
          folderId: folder.id,
          parentId: folder.parentId
        })

        defer.resolve(folder)
      })
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }

  function putMembers(account: Account, folder: Folder, members: string[]) {
    const defer = $q.defer()

    folders
      .updateMembersFolder({
        accountId: account.id,
        folderId: folder.id,
        payload: { members }
      })
      .then((members) => {
        defer.resolve(members)
      })
      .catch((rejection) => defer.reject(rejection.response.data))

    return defer.promise
  }
}
