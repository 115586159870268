import jQuery from 'jquery'

import _cloneDeep from 'lodash/cloneDeep'
import _each from 'lodash/each'
import _filter from 'lodash/filter'

export function foldersTree($q, $http, BASE_URL, API_VERSION, session, context, filteringContext) {
  'ngInject'

  let storedTree = null

  let service = {
    getTree: getTree,
    fetchTree: fetchTree,
    clear: clear,
    traverseTree: traverseTree,
    buildTreeStructure: buildTreeStructure,
    removeEmpty: removeEmpty
  }

  return service

  function clear() {
    storedTree = null
  }

  function store(accountId, tree) {
    storedTree = {
      accountId: accountId,
      tree: tree
    }

    return storedTree
  }

  function fetchTree({ account, folderId = account.folder.id, filter = null, params = {} }) {
    // Retrieve and parse 'folderSort' from local storage
    const folderSort = localStorage.getItem('folderSort')
    let sortName = ''

    if (folderSort) {
      try {
        const parsedSort = JSON.parse(folderSort)
        if (parsedSort[0] && parsedSort[0].key === 'name') {
          sortName = parsedSort[0].direction === 'ASC' ? 'name' : '-name'
        }
      } catch (error) {
        console.error('Failed to parse folderSort from local storage:', error)
      }
    }

    // Merge the default params with any provided params
    const defaultParams = Object.assign(
      {
        include: 'descendants',
        format: 'tree',
        ...(sortName && { sort: sortName }) // Add sort parameter if available
      },
      params
    )

    const filterParams = filteringContext.mergeParams(filteringContext.filters(), defaultParams)
    const url = `${BASE_URL}/accounts/${account.id}/folders/${folderId}`

    return $http
      .get(url, {
        params: filter ? filterParams : defaultParams,
        headers: {
          Accept: API_VERSION
        }
      })
      .then(function (response) {
        let tree

        if (defaultParams.format && defaultParams.format === 'tree') {
          tree = buildTreeStructure(response.data.data)
        } else {
          tree = response.data.data
        }

        if (folderId === account.folder.id && typeof filter === 'undefined') {
          store(account.id, _cloneDeep(tree))
        }

        return tree
      })
  }

  function getTree(account, refresh) {
    let defer = $q.defer()

    if (storedTree && storedTree.accountId === account.id && !refresh) {
      defer.resolve(_cloneDeep(storedTree.tree))
      return defer.promise
    }

    return fetchTree({ account })
  }

  function buildTreeStructure(tree) {
    let treeStructure = _cloneDeep(tree)

    traverseTree(treeStructure, function (folder) {
      if (!folder.children || !folder.children.data) {
        folder.children = []
      } else {
        folder.children = folder.children.data
      }
    })

    return treeStructure
  }

  function traverseTree(tree, callback) {
    _each(tree, function (folder) {
      callback(folder)

      if (folder.children) {
        traverseTree(folder.children, callback)
      }
    })
  }

  function removeEmpty(tree) {
    let emptyRemoved = _filter(tree, 'count')

    if (emptyRemoved.length) {
      traverseTree(emptyRemoved, function (folder) {
        folder.children = _filter(folder.children, 'count')
      })
    }

    return emptyRemoved
  }
}
