import _assign from 'lodash/assign'
import _debounce from 'lodash/debounce'
import _forEach from 'lodash/forEach'

export function Controller(
  $q,
  $scope,
  $state,
  $timeout,
  $stateParams,
  context,
  EventsData,
  foldersData,
  foldersResolves,
  foldersTree,
  filteringContext,
  foldersExpanded,
  recordsViewMode,
  $transitions,
  $window
) {
  'ngInject'
  let vm = this
  let transitionOnStart

  vm.control = {
    shouldShowFolders: shouldShowFolders,
    shouldShowRecords: shouldShowRecords,
    shouldShowActivities: shouldShowActivities
  }

  vm.handled = false

  vm.$onInit = function () {
    vm.account = context.get('account')
    vm.folderMeta = {}
    vm.transitioning = false
    vm.showSort = false
    vm.bucket = $stateParams.bucket
    delete $stateParams.bucket

    foldersExpanded.reset()
    setupFolder()
    if (!vm.handlerAdded) {
      vm.handlerAdded = true

      listen()
    }
  }

  function getFolder() {
    let folderId = $stateParams.folderId || vm.account.folder.id
    const params: any = !vm.hasManyFolders ? { include: 'descendants,count' } : null

    $q.all({
      folder: foldersResolves.folder(vm.account, folderId),
      treeSegment: foldersResolves.treeSegment(vm.account, folderId, params)
    })
      .then(function (resolves) {
        vm.folder = resolves.folder
        vm.treeSegment = resolves.treeSegment

        setupFolder()
      })
      .catch(() => {})
  }

  function setupFolder() {
    vm.folderMeta = _assign(vm.folderMeta, {
      $defaultFilterApplied: filteringContext.defaultFilterApplied(),
      $isActiveFilterParams: filteringContext.isActiveFilterParams(),
      $currentFilterName: filteringContext.currentFilterName(),
      $hasManyFolders: vm.hasManyFolders,
      $bucket: vm.bucket
    })

    if (vm.hasManyFolders || vm.folderMeta.$defaultFilterApplied) {
      vm.folder.children = vm.treeSegment
    } else {
      vm.folder.children = foldersTree.removeEmpty(vm.treeSegment)
    }

    // See if there are folders remaining
    vm.folderMeta.$hasChildren = !!vm.folder.children.length
  }

  function removeEmptyFolders(folders) {
    if (!vm.folderMeta.$defaultFilterApplied) {
      vm.folder.children = foldersTree.removeEmpty(folders)
    } else {
      vm.folder.children = folders
    }
  }

  function listen() {
    let eventsWhitelist = [
      'folder_was_created',
      'access_was_created',
      'access_was_deleted',
      'folder_was_updated',
      'record_was_created',
      'record_was_imported',
      'record_was_cloned',
      'record_was_updated',
      'record_was_moved',
      'record_was_deleted',
      'record_was_undeleted',
      'record_upload_was_added',
      'record_upload_was_updated'
    ]
    let debounce = _debounce(updateFolderEvent, 500)

    _forEach(eventsWhitelist, function (event) {
      $scope.$on(event, debounce)
    })

    $scope.$on('folder_was_deleted', deleteFolderEvent)
    $scope.$on('member_removed_from_folder', deleteFolderEvent)

    $window.addEventListener('folder-sort-change', function (event) {
      getFolder()
    })

    $scope.$on('$destroy', function () {
      // kill listener
      $window.removeEventListener('folder-sort-change', getFolder)
      transitionOnStart()
    })

    transitionOnStart = $transitions.onStart({}, stateChangeStart)
  }

  function stateChangeStart($transition$) {
    if (
      ($transition$.to().name === 'folder' || $transition$.to().name === 'folders') &&
      ($transition$.from().name === 'folder' || $transition$.from().name === 'folders')
    ) {
      vm.transitioning = true
    }
  }

  function updateFolderEvent(e, dto) {
    if (dto.folderId === vm.folder.id || dto.parentId === vm.folder.id) {
      getFolder()
    }
  }

  function deleteFolderEvent(e, folderDto) {
    if ($scope.alreadyHandled) {
      return
    }

    $scope.alreadyHandled = true

    if (folderDto.folderId === vm.folder.id) {
      $timeout(function () {
        if (folderDto.parentId === vm.account.folder.id) {
          $state.go('account')
        } else {
          $state.go('.', { folderId: folderDto.parentId })
        }
      })
    } else {
      updateFolderEvent(e, folderDto)
    }
  }

  function shouldShowFolders() {
    let viewMode = recordsViewMode.get()

    // We are not showing the folders section at all
    if (viewMode === 'record') {
      return false
    }

    if (vm.folder.parentId) {
      if (vm.folderMeta.$hasChildren) {
        // There are folders to show
        return true
      }
      if (vm.folderMeta.$hasRecords === false && !vm.folderMeta.$hasChildren && vm.folderMeta.$defaultFilterApplied) {
        // We are on the default filter and there are no folders or records, so we show the option to add either
        return true
      }

      return false
    }

    return true
  }

  function shouldShowRecords() {
    let viewMode = recordsViewMode.get()

    // We are on record mode, there are records, or we are not yet sure if there are records
    if (viewMode === 'record' || vm.folderMeta.$hasRecords || typeof vm.folderMeta.$hasRecords === 'undefined') {
      return true
    }

    // Root folder, so we know there cant be records
    if (!vm.folder.parentId) {
      return false
    }

    // No folders or records
    if (vm.folderMeta.$hasRecords === false && !vm.folderMeta.$hasChildren) {
      return true
    }

    return false
  }

  function shouldShowActivities() {
    return recordsViewMode.get() === 'folder' && vm.folder.parentId
  }
}
