import _get from 'lodash/get'

import { fetchRecords } from 'AppCore/record/api'

export function Controller(
  $scope,
  foldersExpanded,
  filteringContext,
  AccessesData,
  context,
  $timeout,
  permissionsCheck
) {
  'ngInject'

  let vm = this

  vm.control = {
    toggleChildren: toggleChildren,
    getExpandedState: getExpandedState
  }

  vm.$onInit = function () {
    vm.account = context.get('account')
    vm.folderSort = JSON.parse(localStorage.getItem('folderSort'))
    vm.canMove = permissionsCheck.can('edit', 'folders', vm.account, vm.folder)
    let activeFilter = filteringContext.getActiveFilter()

    vm.count = null
    vm.filterProfile = activeFilter ? activeFilter.profile : null

    AccessesData.folderAccess(vm.folder.id)
      .then(function (access) {
        vm.access = access
      })
      .catch(() => {})

    // If the account is large in folder size, we improve performance by fetching the count
    // as it is needed
    if (vm.folderMeta.$hasManyFolders) {
      getFolderCount()
    }
    // For smaller accounts we've already got the counts in one request
    else {
      vm.count = vm.folder.count
    }
  }

  function getFolderCount() {
    const defaultParams = {
      folder_id: vm.folder.id,
      limit: 1,
      include: 'descendants,count'
    }

    const filterParams = filteringContext.mergeParams(filteringContext.filters(), defaultParams)
    const showSpinner = $timeout(() => {
      vm.foldersLoading = true
    }, 1000)

    fetchRecords({
      accountId: vm.account.id,
      query: filterParams
    })
      .then((response) => {
        // TODO timeout wrapper to invoke angular digest cycle
        $timeout(() => {
          vm.count = _get(response, 'cursor.page.total') || 0
        })
      })
      .finally(() => {
        $timeout.cancel(showSpinner)
        // TODO timeout wrapper to invoke angular digest cycle
        $timeout(() => {
          vm.foldersLoading = false
        })
      })
  }

  function toggleChildren($event) {
    $event.stopPropagation()

    foldersExpanded.toggle(vm.folder.id)
  }

  function getExpandedState() {
    return foldersExpanded.state(vm.folder.id)
  }
}
