import _forEach from 'lodash/forEach'
import _includes from 'lodash/includes'
import _sortBy from 'lodash/sortBy'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _cloneDeep from 'lodash/cloneDeep'

export function Controller($scope, DefinitionsData, tracking) {
  'ngInject'

  let vm = this

  vm.control = {
    close: closeSortPanel,
    add: addToSort,
    updateFolderSort: updateFolderSort,
    remove: removeFromSort,
    directionChange: directionChange,
    folderDirectionChange: folderDirectionChange
  }

  vm.sortedList = []
  vm.unsortedList = []
  vm.unsortedSelected = null
  vm.unsortedSelectedOrder = null
  vm.sortOptions = {}
  vm.sortedFolders = []
  vm.unsortedFolders = []
  vm.unsortedFolderSelectedOrder = 1
  vm.unsortedFolderSelected = null

  vm.$onInit = function () {
    populateSortLists()
    listen()
    retrieveFolderSort()
    retrieveFolderUnsorted()

    vm.sortOptions = {
      axis: 'y',
      handle: '.order-cell-icon',
      stop: function () {
        vm.sortedList = recalculateOrders(vm.sortedList)
        DefinitionsData.setVisibleColumnsSort(DefinitionsData.getVisibleColumnsAsArray())
      }
    }
  }

  function listen() {
    $scope.$on('definitions:changed', function () {
      populateSortLists()
    })

    $scope.$on('sort:changed', function () {
      populateSortLists()
    })
  }

  function retrieveFolderSort() {
    const folderSort = localStorage.getItem('folderSort')
    if (folderSort) {
      vm.sortedFolders = JSON.parse(folderSort)
    } else {
      vm.sortedFolders = [{ order: 1, name: 'Position', direction: 'ASC', key: 'position' }]
      storeFolderSort()
    }
  }

  function storeFolderSort() {
    localStorage.setItem('folderSort', JSON.stringify(vm.sortedFolders))
    window.dispatchEvent(new CustomEvent('folder-sort-change', { detail: vm.sortedFolders[0] }))
  }

  function retrieveFolderUnsorted() {
    const folderUnsorted = localStorage.getItem('folderUnsorted')
    if (folderUnsorted) {
      vm.unsortedFolders = JSON.parse(folderUnsorted)
    } else {
      vm.unsortedFolders = [{ order: 0, name: 'Name', direction: 'ASC', key: 'name' }]
      storeFolderUnsorted()
    }
    vm.unsortedFolderSelected = vm.unsortedFolders[0].key
  }

  function storeFolderUnsorted() {
    localStorage.setItem('folderUnsorted', JSON.stringify(vm.unsortedFolders))
  }

  function populateSortLists() {
    let sortedList = []
    let unsortedList = []

    // populate sort and unsort arrays
    _forEach(DefinitionsData.getVisibleColumnsAsArray(), (definition) => {
      if (!_includes(DefinitionsData.getSortExcludes(), definition.key)) {
        if (definition.order && definition.visibility) {
          sortedList.push(definition)
        } else if (definition.visibility) {
          unsortedList.push(definition)
        }
      }
    })

    vm.sortedList = recalculateOrders(_sortBy(sortedList, 'order'))
    vm.unsortedList = _sortBy(unsortedList, 'key')

    // If we still have unsorted items, make sure we give the select box a value,
    // so that it does not appear blank.
    if (unsortedList.length) {
      vm.unsortedSelected = vm.unsortedList[0].key
      vm.unsortedSelectedOrder = vm.sortedList.length + 1
    }
  }

  // Check the sorted items for order values greater than
  // or equal to the 'order' param, then bump them up by 1.
  function bumpOrders(order) {
    let newSortList = _map(vm.sortedList, function (item) {
      let newItem

      // Return new object for changed item to trigger $onChanges in child componenets
      if (item.order >= order) {
        newItem = _cloneDeep(item)
        newItem.order = newItem.order + 1

        return newItem
      }

      // No changes to item
      return item
    })

    return newSortList
  }

  // Deals with re-ordering, should an item be removed from sort,
  // or the order value exceeds the sortedItems length by 2 or more,
  // this will keep things in uniform.
  function recalculateOrders(list) {
    return _map(list, function (item, index) {
      item.order = index + 1

      return item
    })
  }

  function amendSort(item, order) {
    if (typeof item !== 'undefined') {
      item.order = order

      // If we are inserting an item into the range of existing values
      if (order > 0 && order <= vm.sortedList.length) {
        bumpOrders(order)
      }

      DefinitionsData.setVisibleColumnsSort(DefinitionsData.getVisibleColumnsAsArray())
    }
  }

  function directionChange($event) {
    tracking.trackEvent({
      action: 'changeColumnSortDirection',
      category: 'sortRecords',
      label: $event.item.key
    })

    DefinitionsData.setVisibleColumnsSort(DefinitionsData.getVisibleColumnsAsArray())
  }

  function folderDirectionChange($event) {
    vm.sortedFolders[0].direction = $event.item.direction
    storeFolderSort()
  }

  function addToSort() {
    tracking.trackEvent({
      action: 'addColumnToSort',
      category: 'sortRecords',
      label: vm.unsortedSelected
    })

    let item = _find(vm.unsortedList, { key: vm.unsortedSelected })

    amendSort(item, vm.unsortedSelectedOrder)
  }

  function updateFolderSort() {
    const unsorted = vm.unsortedFolders
    const sorted = vm.sortedFolders
    vm.sortedFolders = unsorted
    vm.sortedFolders[0].order = 1
    vm.unsortedFolders = sorted
    vm.unsortedFolders[0].order = 0
    vm.unsortedFolderSelected = vm.unsortedFolders[0].key
    storeFolderSort()
    storeFolderUnsorted()
    window.dispatchEvent(new CustomEvent('folder-sort-change', { detail: vm.sortedFolders[0] }))
  }

  function removeFromSort($event) {
    tracking.trackEvent({ action: 'removeColumnFromSort', category: 'sortRecords', label: $event.item.key })

    amendSort($event.item, 0)
  }

  function closeSortPanel() {
    vm.showSort = false
  }
}
